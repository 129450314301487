
import { useState } from "react"
import api from "shared/api/axios"

export const useAuth = () => {
  const [user, setUser] = useState(null);

  const signIn = async (username, password) => {
    try {
      const res = await api.post("auth/token/", { username, password });
      const { access } = res.data;
      localStorage.setItem('accessToken', access);
      const profile = await getProfile(access);
      if (profile) {
        setUser({ ...profile, access });
        return true;
      }
    } catch (error) {
      console.error("Sign-in failed", error);
      return false;
    }
  };

  const getProfile = async (token) => {
    try {
      const res = await api.get("auth/profile/", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return res.data;
    } catch (error) {
      console.error("Failed to fetch profile", error);
      return null;
    }
  };

  const signOut = () => {
    api.post("auth/token/blacklist/", {}, { withCredentials: true })
      .then(() => {
        localStorage.removeItem('accessToken');
        setUser(null);
      })
      .catch((error) => {
        console.error("Sign-out failed", error);
      });
  };

  return { user, signIn, signOut };
};