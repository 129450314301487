import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"
import api from "../shared/api/axios"

const filtersToParams = (filters, params, fields) => {
  const selectsFields = fields.selects.map(s => s.id.toString())
  for (const [key, value] of Object.entries(filters)) {
    if (selectsFields.includes(key)){
      if (value && value !== -1) params.append(key.toString(), value)
    }else{
      if (key !== "phone") {
        if (value){
          params.append('results[]', key)
        }
      }
    }
  }
  return params
}

const getOrdersRequest = async (startDate, endDate, filters, fields, page, perPage, showSnackbar) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    page: filters?.phone ? 1 : page,
    page_size: perPage,
    phone: filters?.phone ? filters.phone : ""
  })
  if (filters && fields) params = filtersToParams(filters, params, fields)

  try {
    const res = await api.get(`feedback/?${params.toString()}`)
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Ошибка при получении данных")
    }
    return false
  }
}

export default function useOrders(page, perPage, startDate, endDate, filters, fields){
  const { showSnackbar } = useSnackbarContext()
  const [orders, setOrders] = useState(false)
  
  useEffect(() => {
    setOrders(false)
    const getOrders = async () => {
      setOrders(await getOrdersRequest(startDate, endDate, filters, fields, page, perPage, showSnackbar))
    }
    getOrders()
  }, [filters, startDate, endDate, page, perPage])
  
  return { orders, setOrders }
}