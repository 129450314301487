// src/pages/auth/SignIn/index.jsx
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as LogoSvg } from "../../../assets/images/logo.svg";
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import { useAuthContext } from "../../../hocs/AuthProvider";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function SignIn() {
  const [redirect, setRedirect] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onBlur",
  });

  const { signIn } = useAuthContext();
  const submitHandle = async (v) => {
    const success = await signIn(v.login, v.password);
    if (!success) {
      setError("login", {
        type: "custom",
        message: "Неверный логин/пароль",
      });
    } else {
      setRedirect("/u/");
      reset();
    }
  };

  if (redirect) return <Navigate to={redirect} replace state={false} />;

  return (
    <form
      noValidate
      onSubmit={handleSubmit(submitHandle)}
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        padding: "20px",
        gap: "30px",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          textAlign: "start",
          margin: 0,
        }}
      >
        <LogoSvg />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          padding: "0 30px",
        }}
      >
        <TextField
          sx={{ width: "100%" }}
          label="Логин или Email"
          {...register("login", {
            required: "Введите логин",
            minLength: {
              value: 4,
              message: "Минимум 4 символа",
            },
          })}
          variant="outlined"
          error={errors?.login ? true : false}
          helperText={errors?.login?.message}
        />
        <TextField
          sx={{ width: "100%" }}
          type={showPassword ? "text" : "password"}
          label="Пароль"
          {...register("password", {
            required: "Введите пароль",
            minLength: {
              value: 8,
              message: "Минимум 8 символов",
            },
            maxLength: {
              value: 128,
              message: "Максимум 128 символов",
            },
          })}
          variant="outlined"
          error={errors?.password ? true : false}
          helperText={errors?.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" variant="contained">
          Войти
        </Button>
      </Box>
      <Link to={"/signup"}>У меня нет аккаунта</Link>
    </form>
  );
}