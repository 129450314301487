import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"
import api from "../shared/api/axios"

const getFiltersRequest = async (showSnackbar, url) => {
  try {
    const res = await api.get(url)
    const data = res.data
    let selects = []
    for (const [key, value] of Object.entries(data.selects)) {
      const s = {...value, options: [...value.options, {id: -1, title: 'Не выбрано'}]}
      selects.push(s)
    }
    const f = {
      checkboxs: data.results,
      selects: selects
    }
    return f
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Ошибка при получении данных")
    }
    return false
  }
}

export default function useFilters (url='', initFilters=false){
  const [filters, setFilters] = useState(initFilters)
  const [fields, setFields] = useState(false)
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    const load = async () => {
      setFields(await getFiltersRequest(showSnackbar, url))
    }
    load()
  }, [])

  return { fields, filters, setFilters }
}