import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"
import api from "../shared/api/axios"

const getAnalyticsRequest = async (showSnackbar, { startDate, endDate }) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
  })

  try {
    const res = await api.get(`analytics/?${params.toString()}`);
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа");
    } else {
      showSnackbar("Не удалось получить данные");
    }
    return false;
  }
}

export default function useStats(startDate=dayjs(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), endDate=dayjs(new Date())){
  const { showSnackbar } = useSnackBarCustom()
  const [analytics, setAnalytics] = useState(false)

  useEffect(() => {
    setAnalytics(false)
    const load = async () => {
      const analytics = await getAnalyticsRequest(showSnackbar, { startDate, endDate })
      setAnalytics(analytics)
    }
    load()
  }, [startDate, endDate])

  return { analytics }
}