import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"
import dayjs from "dayjs"
import api from "../shared/api/axios"

const filtersToParams = (filters, params, fields) => {
  const selectsFields = fields.selects.map(s => s.id.toString())
  for (const [key, value] of Object.entries(filters)) {
    if (key === "department") {
      value.map(k => k !== -1 && params.append('departments[]', k))
    }
    if (key === "master") {
      value.map(k => k !== -1 && params.append('master[]', k))
    }
    if (key === "source") {
      value.map(k => k !== -1 && params.append('source[]', k))
    }
    if (key === "category") {
      value.map(k => k !== -1 && params.append('category[]', k))
    }
    if (!selectsFields.includes(key)){
      if (!['phone', 'from_new_customer', 'only_outcoming', 'not_listened', 'no_order', 'no_recent_order'].includes(key)){
        if (value){
          params.append('results[]', key)
        }
      }
    }
  }
  return params
}

const getCallsRequest = async (startDate, endDate, filters, fields, page, perPage, showSnackbar) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    page: filters?.phone || filters.keyword ? 1 : page + 1,
    page_size: perPage,
    phone: filters?.phone ? filters.phone : "",
    keyword: filters?.keyword ? filters.keyword : "",
  })
  if (filters.from_new_customer){
    params.append("from_new_customer", 1)
  }
  if (filters.only_outcoming){
    params.append("only_outcoming", 1)
  }
  if (filters.no_recent_order){
    params.append("no_recent_order", 1)
  }
  if (filters.not_listened){
    params.append("not_listened", 1)
  }
  if (filters.no_order){
    params.append("no_order", 1)
  }
  if (filters && fields) params = filtersToParams(filters, params, fields)

  try {
    const res = await api.get('calls/', { params })
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    }
    console.error('Ошибка при получении звонков', error)
    return false
  }
}

export default function useCalls(filters=false, fields, startDate=dayjs(new Date()), endDate=dayjs(new Date()), page=0, perPage=10){ 
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()
  const [calls, setCalls] = useState(false)

  useEffect(() => {
    setCalls(false)
    const getCalls = async () => {
      const callsData = await getCallsRequest(startDate, endDate, filters, fields, page, perPage, showSnackbar)
      setCalls(callsData)
    }
    getCalls()
  }, [filters, startDate, endDate, page, perPage])

  return { calls, setCalls }
}