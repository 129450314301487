import React from "react"
import { Box, Skeleton, Typography, Button, Tooltip } from "@mui/material"
import { useAuthContext } from "../../../hocs/AuthProvider"
import { useSnackbarContext } from "../../../hocs/SnackbarProvider"
import { ImportExportOutlined } from "@mui/icons-material"

const filtersToParams = (filters, params, fields) => {
  const selectsFields = fields.selects.map((s) => s.id.toString())
  for (const [key, value] of Object.entries(filters)) {
    if (selectsFields.includes(key)) {
      if (value && value !== -1) params.append(key.toString(), value)
    } else {
      if (value) params.append("results[]", key)
    }
  }
  return params
}

const saveStreamCSV = (filename, text) => {
  // this.setState({downloadingCSV: false})
  // setDownloadingFile(false)
  if (window.navigator.msSaveBlob) {
    // IE 10 and later, and Edge.
    var blobObject = new Blob([text], { type: "text/csv" })
    window.navigator.msSaveBlob(blobObject, filename)
  } else {
    // Everthing else (except old IE).
    // Create a dummy anchor (with a download attribute) to click.
    var anchor = document.createElement("a")
    anchor.download = filename
    if (window.URL.createObjectURL) {
      // Everything else new.
      var blobObject = new Blob([text], { type: "text/csv" })
      anchor.href = window.URL.createObjectURL(blobObject)
    } else {
      // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
      // Load up the data into the URI for "download."
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    }
    // Now, click it.
    if (document.createEvent) {
      var event = document.createEvent("MouseEvents")
      event.initEvent("click", true, true)
      anchor.dispatchEvent(event)
    } else {
      anchor.click()
    }
  }
}

const getFileRequest = (
  headers,
  showSnackbar,
  startDate,
  endDate,
  LTV,
  rating,
  sort,
  phone,
  department
) => {
  let params = new URLSearchParams({
    start_date: startDate.format("YYYY-MM-DD"),
    end_date: endDate.format("YYYY-MM-DD"),
    lifetime_value_from: LTV[0],
    lifetime_value_to: LTV[1],
    rating_from: rating[0],
    rating_to: rating[1],
    order_by_visits_frequency: { 1: undefined, 2: 1, 3: 0 }[sort] ?? undefined,
  })
  if (phone) {
    params.append("phone", phone)
  }
  if (department !== -1) {
    params.append("department", department)
  }
  const res = fetch(
    process.env.REACT_APP_API_URL +
      `customers/download_file?` +
      params.toString(),
    {
      method: "GET",
      headers: headers,
    }
  )
    .then((r) => {
      if (res.status === 403) {
        showSnackbar("Недостаточно прав доступа")
        return false
      }
      return r.text()
    })
    .then((res) => saveStreamCSV("phones.csv", res))
    .catch((e) => showSnackbar("Не удалось получить клиентов"))
}

export default function DataCustomers({
  customers = false,
  url,
  fields,
  LTV,
  rating,
  sort,
  phone,
  department,
  startDate,
  endDate,
}) {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackbarContext()
  // const [downloadingFile, setDownloadingFile] = useState(false)

  const downloadFile = () => {
    getAuthHeader().then((h) => {
      getFileRequest(
        h,
        showSnackbar,
        startDate,
        endDate,
        LTV,
        rating,
        sort,
        phone,
        department
      )
    })
  }

  const isNumber = (v) => typeof v === "number"
  const getCount = (count) =>
    count >= 1000 ? `${(count / 1000).toFixed(2)}к` : count.toString()

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "15px",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          padding: 2,
        }}
      >
        <Typography variant="span">{`Количество клиентов: `}</Typography>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          {isNumber(customers.results) ? (
            getCount(customers.results)
          ) : (
            <Skeleton sx={{ width: "120px" }} />
          )}
        </Typography>
        <Tooltip title={"Выгрузить выбранные номера"}>
          <Button
            disabled={!(isNumber(customers.results) && customers.results > 0)}
            onClick={downloadFile}
            variant={"contained"}
            endIcon={<ImportExportOutlined />}
          >
            Экспорт
          </Button>
        </Tooltip>
      </Box>
    </Box>
  )
}
