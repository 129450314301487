import React from "react"
import { QueryClientProvider } from "@tanstack/react-query"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { ThemeProvider } from "@emotion/react"
import { Box } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AuthProvider } from "hocs/AuthProvider"
import { SidebarProvider } from "hocs/SidebarProvider"
import { SnackbarProvider } from "hocs/SnackbarProvider"
import { RouterProvider } from "react-router-dom"
import { router } from "./providers/router"
import { theme } from "./providers/theme"
import { queryClient } from "./providers/query-client"

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <SidebarProvider>
              <Box sx={{ width: "100%", height: "100%" }}>
                <RouterProvider router={router} />
              </Box>
            </SidebarProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </AuthProvider>
  </QueryClientProvider>
  )
}
