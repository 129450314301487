import React, { useState } from "react"
import {
  Box,
  Tab,
  Grid,
  Skeleton,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material"
import Header from "../../components/Header"
import { TabContext, TabList } from "@mui/lab"
import CustomTabPanel from "../../components/CustomTabPanel"
import dayjs from "dayjs"
import Card from "./components/Card"
import Calls from "./components/Calls"
import Feedback from "./components/Feedback"
import General from "./components/General"
import Tasks from "./components/Tasks"
import { DatePicker } from "@mui/x-date-pickers"
import useFilters from "../../hooks/useFilters"

const Content = ({ startDate, endDate, departments }) => {
  return (
    <Grid
      spacing={2}
      container
      sx={{
        overflow: "visible",
        boxSizing: "border-box",
      }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          boxSizing: "border-box",
        }}
      >
        <Calls
          startDate={startDate}
          endDate={endDate}
          departments={departments}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          boxSizing: "border-box",
        }}
      >
        <General
          startDate={startDate}
          endDate={endDate}
          department={departments}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          boxSizing: "border-box",
        }}
      >
        <Feedback
          startDate={startDate}
          endDate={endDate}
          departments={departments}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          boxSizing: "border-box",
        }}
      >
        <Grid container spacing={2}>
          <Grid sx={{ height: "50%" }} item xs={12} sm={12} lg={12}>
            <Tasks startDate={startDate} endDate={endDate} />
          </Grid>
          <Grid sx={{ height: "50%" }} item xs={12} sm={12} lg={12}>
            <Card title={"Рассылки"}>
              <Skeleton sx={{ width: "100%" }} />
              <Skeleton sx={{ width: "100%" }} />
              {/* <Typography>{`Звонки: (10) (100%)`}</Typography>
              <Typography>{`Обратная связь: (10) (100%)`}</Typography> */}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const CustomPeriod = ({ departments }) => {
  const [startDate, setStartDate] = useState(dayjs(new Date()))
  const [endDate, setEndDate] = useState(dayjs(new Date()))

  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const size = isSmScreen ? "small" : "medium"

  return (
    <>
      <Grid
        container
        pl={isSmScreen && 2}
        py={2}
        spacing={2}
        display={"flex"}
        justifyContent={"start"}
      >
        <Grid item>
          <DatePicker
            label="Дата начала"
            value={startDate}
            onChange={(v) => setStartDate(v)}
            slotProps={{ textField: { size: size } }}
          />
        </Grid>
        <Grid item>
          <DatePicker
            label="Дата окончания"
            value={endDate}
            onChange={(v) => setEndDate(v)}
            slotProps={{ textField: { size: size } }}
          />
        </Grid>
      </Grid>
      <Content
        startDate={startDate}
        endDate={endDate}
        department={departments}
      />
    </>
  )
}

export default function LkMain() {
  const [tab, setTab] = useState("0")
  const [departments, setDepartments] = useState([-1])
  const handleChange = (e, v) => setTab(v)
  const { fields } = useFilters("selects/calls/")

  return (
    <TabContext value={tab}>
      <Box
        flexDirection={"column"}
        sx={{
          display: "flex",
          width: "100%",
          height: "max-content",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        <Header header="Главная" />
        <TabList
          sx={{ width: "100%", height: "50px", padding: 0 }}
          onChange={handleChange}
          allowScrollButtonsMobile
          aria-label="lab API tabs example"
        >
          <Tab label="Прошлый месяц" value="0" />
          <Tab label="90 дней" value="1" />
          <Tab label="Год" value="2" />
          <Tab label="Выбрать период" value="3" />
        </TabList>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 2, padding: 2 }}
          >
            <Typography variant="span">Департамент:</Typography>
            <Select
              size="small"
              defaultValue={departments}
              onChange={(e) => setDepartments(e.target.value)}
              multiple
            >
              {/* <MenuItem value={-1}>Не выбрано</MenuItem> */}
              {fields &&
                fields.selects[0].options.map((op) => (
                  <MenuItem value={op.id}>{op.title}</MenuItem>
                ))}
            </Select>
          </Box>
          <CustomTabPanel value={"0"}>
            <Content
              // Полный предыдущий месяц
              startDate={dayjs(new Date().setDate(1)).subtract(1, "month")}
              endDate={dayjs(new Date().setDate(0))}
              departments={departments}
            />
          </CustomTabPanel>
          <CustomTabPanel value={"1"}>
            <Content
              startDate={dayjs(new Date()).subtract(90, "days")}
              endDate={dayjs(new Date())}
              departments={departments}
            />
          </CustomTabPanel>
          <CustomTabPanel value={"2"}>
            <Content
              startDate={dayjs(new Date()).subtract(1, "year")}
              endDate={dayjs(new Date())}
              departments={departments}
            />
          </CustomTabPanel>
          <CustomTabPanel value={"3"}>
            <CustomPeriod departments={departments} />
          </CustomTabPanel>
        </Box>
      </Box>
    </TabContext>
  )
}
