import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"
import api from "../shared/api/axios"

const getCustomerRequest = async (showSnackbar, phone) => {
  let params = new URLSearchParams({
    phone: phone,
  })

  try {
    const res = await api.get(`customers/?${params.toString()}`);
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа");
    } else {
      showSnackbar("Ошибка при получении данных");
    }
    return false;
  }
}

export default function useCustomer (phone){
  const [customer, setCustomer] = useState(false)
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    setCustomer(false)
    const load = async () => {
      const res = await getCustomerRequest(showSnackbar, phone)
      setCustomer(res ? res : false)
    }
    load()
  }, [phone])
  return { customer }
}