import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"
import api from "../shared/api/axios"

const getDepartmentsRequest = async (showSnackbar) => {
  try {
    const res = await api.get("departments/")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось получить подразделения")
    }
    return false
  }
}

const createDepartmentRequest = async (data, showSnackbar) => {
  try {
    const res = await api.post("departments/", data)
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось создать подразделение")
    }
    return false
  }
}

export default function useDepartments() {
  const { showSnackbar } = useSnackBarCustom()
  const [departments, setDepartments] = useState(false)

  useEffect(() => {
    const load = async () => {
      const departments = await getDepartmentsRequest(showSnackbar)
      setDepartments(departments)
    }
    load()
  }, [])

  const createDepartment = (data) => {
    createDepartmentRequest(data, showSnackbar).then((d) => {
      if (d)
        setDepartments({
          ...departments,
          results: [...departments.results, d],
        })
    })
  }

  return { departments, createDepartment }
}
