import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"
import api from "../shared/api/axios"

const filtersToParams = (filters, params, fields) => {
  const selectsFields = fields.selects.map(s => s.id.toString())
  for (const [key, value] of Object.entries(filters)) {
    if (selectsFields.includes(key)){
      if (value && value !== -1) params.append(key.toString(), value)
    }else{
        if (key !== "from_new_customer"){
          if (key !== "no_order"){
            if (value){
              params.append('results[]', key)
            }
          }
        }
    }
  }
  return params
}

const getCustomersRequest = async (showSnackbar, startDate, endDate, fields, filters, url) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
  })
  if (filters.from_new_customer){
    params.append("from_new_customer", 1)
  }
  if (filters.no_order){
    params.append("no_order", 1)
  }
  if (filters && fields) params = filtersToParams(filters, params, fields)

  try {
    const res = await api.get(`${url}?${params.toString()}`)
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Ошибка при получении данных")
    }
    return false
  }
}

export default function useCounter (startDate, endDate, fields, filters, url){
  const [customers, setCustomers] = useState(false)
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    setCustomers(false)
    const load = async () => {
      const res = await getCustomersRequest(showSnackbar, startDate, endDate, fields, filters, url)
      setCustomers(res ? res : false)
    }
    load()
  }, [filters, startDate, endDate])
  return { customers }
}