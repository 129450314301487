import React, { useEffect, useState } from "react"
import Header from "../../components/Header"
import dayjs from "dayjs"
import { Box, Checkbox, Stack, Typography, Pagination, Select, MenuItem } from "@mui/material"
import CallModal from "../../components/CallModal"
import useCalls from "../../hooks/useCalls"
import useFilters from "../../hooks/useFilters"
import CallsCards from "../../components/CallsCards"
import { useOutletContext, useSearchParams } from "react-router-dom"

export default function Operator() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [startDate, setStartDate] = useState(
    searchParams.get("startDate")
      ? dayjs(searchParams.get("startDate"), "DD-MM-YYYY")
      : dayjs(new Date())
  )
  const [endDate, setEndDate] = useState(
    searchParams.get("endDate")
      ? dayjs(searchParams.get("endDate"), "DD-MM-YYYY")
      : dayjs(new Date())
  )
  const [modalCall, setModalCall] = useState(false)
  const [notListened, setNotListened] = useState(false)
  const [page, setPage] = useState(Number(searchParams.get("page") || 0))
  const [perPage, setPerPage] = useState(10)
  const { fields, filters, setFilters } = useFilters("selects/calls/")
  const { calls, setCalls } = useCalls(
    filters,
    false,
    startDate,
    endDate,
    page,
    perPage
  )
  const pagePadding = useOutletContext()

  const updateValue = (setParam, paramName) => (newValue) => {
    setParam(newValue)
    setSearchParams((params) => {
      if (newValue instanceof dayjs) {
        newValue = newValue.format("DD-MM-YYYY")
      }
      params.set(paramName, newValue)
      return params
    })
  }

  useEffect(() => {
    setFilters({ not_listened: notListened ? 1 : 0 })
    setPage(0)
  }, [notListened])

  const onChangePage = (e, p) => {
    setPage(p)
    setCalls(false)
  }

  const onChangePerPage = (e) => {
    setPerPage(e.target.value)
    setPage(0)
    setCalls(false)
  }

  return (
    <Box
      flexDirection={"column"}
      display={"flex"}
      sx={{
        gap: pagePadding,
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <CallModal
        calls={calls}
        setCalls={setCalls}
        fields={fields}
        onClose={() => setModalCall(false)}
        call={modalCall}
      />
      <Header
        startDate={startDate}
        setStartDate={updateValue(setStartDate, "startDate")}
        endDate={endDate}
        setEndDate={updateValue(setEndDate, "endDate")}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          borderRadius: "15px",
        }}
      >
        <Stack pl={2} direction={"row"} alignItems={"center"}>
          <Typography>Не прослушано</Typography>
          <Checkbox
            checked={notListened}
            onChange={(e) => setNotListened(e.target.checked)}
          />
        </Stack>
        <CallsCards
          filters={filters}
          setFilters={setFilters}
          calls={calls}
          setCalls={setCalls}
          setModalCall={setModalCall}
          perPage={perPage}
        />
        <Box
          sx={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <Select value={perPage} onChange={onChangePerPage} size={"small"}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Pagination
            page={page}
            onChange={onChangePage}
            count={Math.ceil(calls.count / perPage)}
            align="left"
            color="primary"
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </Box>
    </Box>
  )
}
