import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { useSnackBarCustom } from "../../../hooks/useSnackbarCustom"
import api from "../../../shared/api/axios"

const getRequest = async (showSnackbar, {startDate, endDate, departments}) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
  })
  if (departments){
    departments.forEach((d) => {
      if (d !== -1) params.append('departments[]', d)
    })
  }
  try {
    const res = await api.get("main_page/feedback/", { params })
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось получить данные")
    }
    return false
  }
}

export default function useFeedback(startDate=dayjs(new Date()), endDate=dayjs(new Date()), departments=false){
  const { showSnackbar } = useSnackBarCustom()
  const [data, setData] = useState(false)

  useEffect(() => {
    setData(false)
    const load = async () => {
      const d = await getRequest(showSnackbar, {startDate: startDate, endDate: endDate, departments: departments})
      setData(d)
    }
    load()
  }, [startDate, endDate, departments])

  return { feedback: data }
}