import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"
import api from "../shared/api/axios"

const getsendMessagesRequest = async (showSnackbar, startDate, endDate) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
  })

  try {
    const res = await api.get(`send_messages/newsletters?${params.toString()}`);
    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа");
    } else {
      showSnackbar("Не удалось выполнить запрос");
    }
    return false;
  }
}

export default function useSendMessages(startDate, endDate) {
  const { showSnackbar } = useSnackBarCustom()
  const [sendMessages, setSendMessages] = useState(false)

  useEffect(() => {
    const load = async () => {
      const sendMessages = await getsendMessagesRequest(showSnackbar, startDate, endDate)
      setSendMessages(sendMessages)
    }
    load()
  }, [startDate, endDate])

  return { sendMessages }
}
