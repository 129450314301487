import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"
import api from "../shared/api/axios"

const createTaskRequest = async (data, showSnackbar) => {
  try {
    const res = await api.post("tasks/", data)
    showSnackbar("Задача создана")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось создать задачу")
    }
    return false
  }
}

const saveTaskRequest = async (data, showSnackbar) => {
  try {
    const res = await api.patch(`tasks/${data.id}/update/`, data)
    showSnackbar("Задача сохранена")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось сохранить задачу")
    }
    return false
  }
}

const getTasksRequest = async (showSnackbar) => {
  try {
    const res = await api.get("tasks/")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось получить задачи")
    }
    return false
  }
}

const getFiltersRequest = async (showSnackbar) => {
  try {
    const res = await api.get("tasks/fields/")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось получить задачи")
    }
    return false
  }
}

export default function useTasks() {
  const [nonFilteredTasks, setNonFilteredTasks] = useState()
  const [tasks, setTasks] = useState(false)
  const [fields, setFields] = useState(false)
  const [filters, setFilters] = useState(false)
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    const load = async () => {
      const tasksValue = await getTasksRequest(showSnackbar)
      setTasks(tasksValue)
      setNonFilteredTasks(tasksValue)
      setFields(await getFiltersRequest(showSnackbar))
    }
    if (!filters) load()
  }, [])

  useEffect(() => {
    if (filters && nonFilteredTasks.count > 1) {
      const newTasks = nonFilteredTasks.results.filter((t) => {
        if (filters?.status && filters.status !== -1) {
          if (t.status !== filters.status) return
        }
        if (filters?.deparment && filters.deparment !== -1) {
          if (t.deparment !== filters.deparment) return
        }
        return t
      })
      setTasks({ count: newTasks.length, results: newTasks })
    }
  }, [filters])

  const createTask = (data) => {
    createTaskRequest(data, showSnackbar).then((d) => {
      if (d) {
        setTasks({ ...tasks, results: [...tasks.results, d] })
        setNonFilteredTasks({
          ...nonFilteredTasks,
          results: [...nonFilteredTasks.results, d],
        })
      }
    })
  }

  const saveTask = (data, onClose) => {
    saveTaskRequest(data, showSnackbar).then((d) => {
      if (d) {
        setTasks({ ...tasks, results: [...tasks.results, d] })
        setNonFilteredTasks({
          ...nonFilteredTasks,
          results: [...nonFilteredTasks.results, d],
        })
        onClose()
      }
    })
  }

  return {
    tasks,
    setTasks,
    fields,
    setFilters,
    createTask,
    saveTask,
  }
}
