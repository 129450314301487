import React from "react"
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  ListItem,
  List,
  Skeleton,
  Grid,
} from "@mui/material"

const CallCard = ({ call, onClick, size }) => {
  return (
    <ListItem sx={{ width: "100%", padding: 2 }}>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          background: call.result?.result_type ? "#eee" : "inherit",
        }}
      >
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {call.phone}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>{call.date_string}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{call.department?.title || "-"}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Результат: {call.result?.result_type?.title || "-"}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Результат AI: {call.ai_result || "-"}</Typography>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography>Комментарий: {call.result?.comment || "-"}</Typography>
            <Typography>Комментарий AI: {call.ai_comment || "-"}</Typography>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" size={size} onClick={onClick}>
              Подробнее
            </Button>
          </Box>
        </CardContent>
      </Card>
    </ListItem>
  )
}

export default function CallsCards({ calls, setModalCall }) {
  const callDetailsWrapper = (call) => () => setModalCall(call)

  if (calls === false) {
    // Show loading skeletons
    return (
      <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
        <List>
          {[...Array(5)].map((_, index) => (
            <ListItem key={index} sx={{ width: "100%", padding: 2 }}>
              <Card variant="outlined" sx={{ width: "100%", margin: "15px 0" }}>
                <CardContent>
                  <Skeleton variant="text" width="60%" height={30} />
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="text" width="80%" />
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      </Box>
    )
  }

  if (calls.results.length === 0) {
    // Show message if no calls are available
    return (
      <Box sx={{ padding: 2, width: "100%", height: "100%", overflow: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6">Нет доступных звонков</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ width: "100%", height: "100%"}}>
      <List>
        {calls.results.map((call) => (
          <CallCard
            key={call.id}
            call={call}
            onClick={callDetailsWrapper(call)}
          />
        ))}
      </List>
    </Box>
  )
} 