import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"
import api from "../shared/api/axios"

const getPhonesRequest = async (showSnackbar) => {
  try {
    const res = await api.get("send_messages/config/")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось получить телефоны")
    }
    return false
  }
}

export default function usePhones() {
  const { showSnackbar } = useSnackBarCustom()
  const [phones, setPhones] = useState(false)

  useEffect(() => {
    const load = async () => {
      const phones = await getPhonesRequest(showSnackbar)
      setPhones(phones)
    }
    load()
  }, [])

  return { phones }
}
