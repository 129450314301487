import React from "react"
import { useLocation, Navigate } from "react-router-dom"
import { useAuthContext } from "../AuthProvider"

export default function PrivatePage({
  children,
  allowedPageTypes = ["OWNER"],
}) {
  const { user } = useAuthContext()
  const location = useLocation()

  if (!user) {
    // If the user is not authenticated, redirect to the sign-in page
    return <Navigate to="/signin" replace state={{ from: location }} />
  }

  if (user?.user?.type && allowedPageTypes.includes(user.user.type)) {
    // If the user is authenticated and has the correct type, render the children
    return children
  }

  // If the user is authenticated but does not have the correct type, redirect to a "not authorized" page or handle it accordingly
  return <Navigate to="/u/" replace />
}
