import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackBarCustom } from "./useSnackbarCustom"
import api from "../shared/api/axios"

const getTemplatesRequest = async (showSnackbar) => {
  try {
    const res = await api.get("customers/template/")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось получить шаблоны")
    }
    return false
  }
}

const saveTemplateRequest = async (data, showSnackbar) => {
  try {
    const res = await api.patch(`customers/template/${data.id}/update/`, data)
    showSnackbar("Шаблон сохранен")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось сохранить шаблон")
    }
    return false
  }
}

const createTemplateRequest = async (data, showSnackbar) => {
  try {
    const res = await api.post("customers/template/create/", data)
    showSnackbar("Шаблон создан")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось создать шаблон")
    }
    return false
  }
}

export default function useTemplates() {
  const { showSnackbar } = useSnackBarCustom()
  const [templates, setTemplates] = useState(false)

  useEffect(() => {
    const load = async () => {
      const templates = await getTemplatesRequest(showSnackbar)
      setTemplates(templates)
    }
    load()
  }, [])

  const saveTemplate = (data) => {
    saveTemplateRequest(data, showSnackbar).then((d) => {
      if (d) {
        setTemplates(templates.map((t) => (t.id === d.id ? d : t)))
      }
    })
  }

  const createTemplate = (data) => {
    createTemplateRequest(data, showSnackbar).then((d) => {
      if (d) {
        setTemplates(templates.map((t) => (t.id === d.id ? d : t)))
      }
    })
  }

  return { templates, saveTemplate, createTemplate }
}
