import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import MailListFilter from "../MailListFilter"
import SendMailList from "../SendMailList"
import { useForm } from "react-hook-form"
import useFilters from "../../../hooks/useFilters"
import TemplateModal from "../TemplatesModal"
import ClientFilter from "components/ClientFilter/ui/client-filter"
import useCustomers from "hooks/useCustomers"
import DataCustomers from "./DataCustomers"
import { useAuthContext } from "hocs/AuthProvider"
import { useSnackBarCustom } from "hooks/useSnackbarCustom"

const getCustomersRequest = async (
  headers,
  showSnackbar,
  startDate,
  endDate,
  page,
  LTV,
  rating,
  sort,
  phone,
  department
) => {
  let params = new URLSearchParams({
    start_date: startDate.format("YYYY-MM-DD"),
    end_date: endDate.format("YYYY-MM-DD"),
    lifetime_value_from: LTV[0],
    lifetime_value_to: LTV[1],
    rating_from: rating[0],
    rating_to: rating[1],
    order_by_visits_frequency: { 1: undefined, 2: 1, 3: 0 }[sort] ?? undefined,
  })
  if (phone) {
    params.append("phone", phone)
  }
  if (department !== -1) {
    params.append("department", department)
  }
  const res = await fetch(
    process.env.REACT_APP_API_URL + "customers/counter?" + params.toString(),
    {
      headers: headers,
      method: "GET",
    }
  ).catch((e) => showSnackbar("Не удалось загрузить"))
  if (res.status === 403) {
    showSnackbar("Недостаточно прав доступа")
    return false
  }
  if (res?.ok && res.ok) {
    const resData = await res.json()
    return resData
  }
  showSnackbar("Не удалось загрузить")
  return false
}

function useCounter(
  startDate,
  endDate,
  page,
  LTV,
  rating,
  sort,
  phone,
  department
) {
  const { getAuthHeader } = useAuthContext()
  const { showSnackbar } = useSnackBarCustom()
  const [customers, setCustomers] = useState(false)

  useEffect(() => {
    setCustomers(false)
    const load = async () => {
      const headers = await getAuthHeader()
      const customers = await getCustomersRequest(
        headers,
        showSnackbar,
        startDate,
        endDate,
        page,
        LTV,
        rating,
        sort,
        phone,
        department
      )
      setCustomers(customers)
    }
    load()
  }, [startDate, endDate, page, LTV, rating, sort, phone, department])

  return { customers, setCustomers }
}

export default function Clients({ startDate, endDate, setModal, size }) {
  const form = useForm({ mode: "onBlur" })
  const [page, setPage] = useState(1)
  const [rating, setRating] = useState([0.0, 5.0])
  const [LTV, setLTV] = useState([0, 1_000_000])
  const [sort, setSort] = useState(1)
  const [department, setDepartment] = useState(-1)
  const [phone, setPhone] = useState(false)
  const { fields } = useFilters("selects/calls/", false)
  const { customers, setCustomers } = useCounter(
    startDate,
    endDate,
    page,
    LTV,
    rating,
    sort,
    phone?.phone || false,
    department
  )
  // const { fields, filters, setFilters } = useFilters("selects/calls/")
  const [templateModal, setTemplateModal] = useState(false)
  // const { customers } = useCounter(
  //   startDate,
  //   endDate,
  //   fields,
  //   filters,
  //   "customers/counter/"
  // )

  return (
    <Grid
      container
      // spacing={2}
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        boxSizing: "border-box",
      }}
    >
      {templateModal && (
        <TemplateModal
          onClose={() => setTemplateModal(false)}
          setText={(t) => form.setValue("text", t)}
        />
      )}
      <Grid item xs={12} sm={9} md={9} lg={9} p={2}>
        <ClientFilter
          fields={fields}
          LTV={LTV}
          setLTV={setLTV}
          rating={rating}
          setRating={setRating}
          sort={sort}
          setSort={setSort}
          phone={phone}
          setPhone={setPhone}
          department={department}
          setDepartment={setDepartment}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} p={2}>
        <DataCustomers
          customers={customers}
          startDate={startDate}
          endDate={endDate}
          fields={fields}
          LTV={LTV}
          rating={rating}
          sort={sort}
          phone={phone?.phone || false}
          department={department}
          url={"customers/download_file/"}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} p={2}>
        {/* <SendMailList
          url={"customers/send/"}
          startDate={startDate}
          endDate={endDate}
          fields={fields}
          filters={filters}
          mailForm={form}
          size={size}
          setModal={setModal}
          setTemplateModal={setTemplateModal}
        /> */}
      </Grid>
    </Grid>
  )
}
