import { useEffect, useState } from "react"
import { useAuthContext } from "../hocs/AuthProvider"
import { useSnackbarContext } from "../hocs/SnackbarProvider"
import api from "../shared/api/axios"

const saveConfigRequest = async (data, showSnackbar) => {
  try {
    const res = await api.patch(`tasks/${data.id}/update/`, data)
    showSnackbar("Задача сохранена")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось сохранить задачу")
    }
    return false
  }
}

const getConfigRequest = async (showSnackbar) => {
  try {
    const res = await api.get("company/config/")
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось получить настройки")
    }
    return false
  }
}

export default function useConfig() {
  const [config, setConfig] = useState(false)
  const { showSnackbar } = useSnackbarContext()

  useEffect(() => {
    const load = async () => {
      const config = await getConfigRequest(showSnackbar)
      setConfig(config)
    }
    if (!config) load()
  }, [])

  const saveConfig = (data) => {
    saveConfigRequest(data, showSnackbar).then((d) => {
      if (d) {
        setConfig(d)
      }
    })
  }

  return {
    config, saveConfig
  }
}
