import React from "react"
import { Link, useMatch } from "react-router-dom"
import { ReactComponent as LogoSvg } from "../../../assets/images/logo.svg"
import MainIcon from "../../../assets/images/main.png"
import OperatorIcon from "../../../assets/images/operator.png"
import CompanyIcon from "../../../assets/images/company.png"
import AnalyticsIcon from "../../../assets/images/analytics.png"
import StatsIcon from "../../../assets/images/stats.png"
import FeedbackIcon from "../../../assets/images/feedback.png"
import TasksIcon from "../../../assets/images/tasks.png"
import MarketerIcon from "../../../assets/images/marketer.png"
import ActiveMainIcon from "../../../assets/images/main-active.png"
import ActiveOperatorIcon from "../../../assets/images/operator-active.png"
import ActiveAnalyticsIcon from "../../../assets/images/analytics-active.png"
import ActiveStatsIcon from "../../../assets/images/stats-active.png"
import ActiveFeedbackIcon from "../../../assets/images/feedback-active.png"
import ActiveTasksIcon from "../../../assets/images/tasks-active.png"
import ActiveMarketerIcon from "../../../assets/images/marketer-active.png"
import ActiveCompanyIcon from "../../../assets/images/company-active.png"

import {
  Box,
  Grid,
  Hidden,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useAuthContext } from "../../../hocs/AuthProvider"
import { AddBoxOutlined, CloseOutlined } from "@mui/icons-material"
import { useSidebarContext } from "../../../hocs/SidebarProvider"

const pages = [
  {
    path: "/u/",
    name: "Главная",
    icon: MainIcon,
    activeIcon: ActiveMainIcon,
    allowedUserTypes: ["OWNER", "MANAGER", "OPERATOR", "SPECIAL_GUEST"],
  },
  {
    path: "/u/company",
    name: "Компания",
    icon: CompanyIcon,
    activeIcon: ActiveCompanyIcon,
    allowedUserTypes: ["OWNER"],
  },
  {
    path: "/u/operator",
    name: "Анализ звонков",
    icon: OperatorIcon,
    activeIcon: ActiveOperatorIcon,
    allowedUserTypes: ["OWNER", "MANAGER", "EMPLOYEE", "OPERATOR"],
  },
  {
    path: "/u/clients",
    name: "Клиенты",
    icon: OperatorIcon,
    activeIcon: ActiveOperatorIcon,
    allowedUserTypes: ["OWNER", "MANAGER", "EMPLOYEE", "OPERATOR"],
  },
  {
    path: "/u/analytics",
    name: "Аналитика",
    icon: AnalyticsIcon,
    activeIcon: ActiveAnalyticsIcon,
    allowedUserTypes: ["OWNER", "MANAGER", "SPECIAL_GUEST"],
  },
  {
    path: "/u/stats",
    name: "Статистика",
    icon: StatsIcon,
    activeIcon: ActiveStatsIcon,
    allowedUserTypes: ["OWNER", "MANAGER"],
  },
  {
    path: "/u/feedback",
    name: "Обратная связь",
    icon: FeedbackIcon,
    activeIcon: ActiveFeedbackIcon,
    allowedUserTypes: ["OWNER", "MANAGER", "EMPLOYEE"],
  },
  {
    path: "/u/tasks",
    name: "Задачи",
    icon: TasksIcon,
    activeIcon: ActiveTasksIcon,
    allowedUserTypes: ["OWNER", "MANAGER", "EMPLOYEE"],
  },
  {
    path: "/u/marketer",
    name: "Кабинет маркетолога",
    icon: MarketerIcon,
    activeIcon: ActiveMarketerIcon,
    allowedUserTypes: ["OWNER", "MANAGER", "EMPLOYEE"],
  },
]

const PageLink = ({ page, theme, onClose }) => {
  const isActive = useMatch(page.path)
  const isSmScreen = useMediaQuery(theme.breakpoints.only("sm"))

  return (
    <Grid
      item
      sx={{
        width: isSmScreen ? "auto" : "100%",
      }}
    >
      <Link onClick={onClose} style={{ textDecoration: "none" }} to={page.path}>
        <Box
          padding={1}
          sx={{
            width: "100%",
            borderRadius: "5px",
            boxSizing: "border-box",
            height: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: isActive
              ? theme.palette.secondary.main
              : "inherit",
          }}
        >
          <img
            style={{ width: "25px", height: "25px" }}
            src={isActive ? page.activeIcon : page.icon}
          />
          <Hidden only={"sm"}>
            <Typography
              fontSize={"14px"}
              marginLeft={1}
              variant="span"
              color={isActive ? "#fff" : "#000"}
            >
              {page.name}
            </Typography>
          </Hidden>
        </Box>
      </Link>
    </Grid>
  )
}

// const Loading = () => {
//   return (
//     <Box>
//       {[...Array(10)].map((e, index) => (
//         <Box sx={{ width: "100%" }} key={index}>
//           <Skeleton />
//         </Box>
//       ))}
//     </Box>
//   )
// }

export default function Sidebar({ theme, sidebarWidth }) {
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const { user } = useAuthContext()
  console.log(user)
  const { isOpen, onClose } = useSidebarContext()
  let pagesReady = []

  if (user?.user && user.user.type) {
    pagesReady = pages.filter((page) =>
      page.allowedUserTypes.includes(user.user.type.toString())
    )
  }

  return (
    <Box
      padding={2}
      sx={{
        left: isSmScreen && !isOpen ? "-100%" : "0",
        top: 0,
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "auto",
        minHeight: "100%",
        position: "fixed",
        width: sidebarWidth,
        zIndex: "inherit",
        boxSizing: "border-box",
        overflow: "auto",
        background: "#fff",
        boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
      }}
    >
      <Hidden only={"sm"}>
        <Box
          pb={4}
          sx={{
            width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to={"/u/"}>
            <LogoSvg />
          </Link>
          <Hidden smUp>
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Hidden>
        </Box>
      </Hidden>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        {pagesReady.map((p) => (
          <PageLink key={p.name} page={p} theme={theme} onClose={onClose} />
        ))}
      </Box>
      <Box sx={{ display: "flex", width: "100%" }}></Box>
    </Box>
  )
}
