import { SearchOff } from "@mui/icons-material"
import {
  Table,
  TableBody,
  Box,
  Button,
  Typography,
  TableFooter,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  IconButton,
  useTheme,
  useMediaQuery,
  Skeleton,
  Tooltip,
} from "@mui/material"
import expandIcon from "../../assets/images/expand.png"
import shrinkIcon from "../../assets/images/shrink.png"
import React, { createRef } from "react"
import SearchByPhone from "../SearchByPhone"
import SearchByWord from "../SearchByWord"

const getHighlightedText = (text, highlight) => {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"))
  return (
    <>
      {parts.map((part) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span style={{ background: "yellow" }}>{part}</span>
        ) : (
          part
        )
      )}
    </>
  )
}

const valueOrNot = (value) => (value ? value : "-")

const Loading = () => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Table stickyHeader>
        <TableBody>
          {[...Array(10)].map((e, index) => (
            <TableRow key={index}>
              <TableCell align="center">
                <Skeleton />
              </TableCell>
              <TableCell align="center">
                <Skeleton />
              </TableCell>
              <TableCell align="center">
                <Skeleton />
              </TableCell>
              <TableCell align="center">
                <Skeleton />
              </TableCell>
              <TableCell align="center">
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

const ExpandTable = ({ fullTable, setFullTalbe }) => {
  return (
    <Box sx={{ boxSizing: "border-box", width: "100%", height: "40px" }}>
      <IconButton
        onClick={() => setFullTalbe(!fullTable)}
        sx={{ width: "40px", height: "40px" }}
      >
        <img
          style={{ height: "100%", width: "100%" }}
          alt="hide filter"
          src={fullTable ? shrinkIcon : expandIcon}
        />
      </IconButton>
    </Box>
  )
}

const NotCalls = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexFlow: "column",
        gap: "10px",
        paddingY: 3,
      }}
    >
      <Typography variant="h6">Звонков не найдено</Typography>
      <SearchOff sx={{ width: "40px", height: "40px" }} />
    </Box>
  )
}

const Header = ({ calls }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center">
          <Typography variant="span">Дата</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="span">Номер</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="span">Подразделение</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="span">Результат</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="span">Результат AI</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="span">Комментарий</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="span">Комментарий AI</Typography>
        </TableCell>
        {/* <TableCell align="center">
          <Typography variant="span">Детали</Typography>
        </TableCell> */}
        <TableCell>
          <Box sx={{ display: "flex", flexFlow: "row nowrap", gap: "10px" }}>
            <Typography whiteSpace={"nowrap"} variant="span">
              Общая сумма:{" "}
            </Typography>
            <Typography sx={{ fontWeight: 600 }} whiteSpace={"nowrap"}>
              {calls?.stats
                ? calls.stats.total_sum
                  ? calls.stats.total_sum
                  : "-"
                : "-"}{" "}
              руб.
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const CallRow = ({
  call: { id, date, department, result, phone, ai_result, ai_comment },
  onClick,
  searchQuery,
  size,
}) => {
  let phoneEl = phone
  if (phone.toString().includes(searchQuery)) {
    // Найдено совпадение с поисковым запросом
    phoneEl = getHighlightedText(phone, searchQuery)
  }

  return (
    <TableRow
      onClick={onClick}
      sx={{
        "&:hover": {
          cursor: "pointer",
          background: "#ddd",
        },
        background: result?.result_type ? "#eee" : "inherit",
      }}
    >
      <TableCell
        size={size}
        align="center"
        sx={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          textWrap: "nowrap",
        }}
      >
        {valueOrNot(date)}
      </TableCell>
      <TableCell size={size} align="center">
        {phoneEl}
      </TableCell>
      <TableCell size={size} align="center">
        {department ? valueOrNot(department.title) : "-"}
      </TableCell>
      <TableCell size={size} align="center">
        {result?.result_type ? result.result_type.title : "-"}
      </TableCell>
      <TableCell size={size} align="center">
        {ai_result ? valueOrNot(ai_result) : "-"}
      </TableCell>
      <TableCell size={size} align="center">
        {result?.comment ? result.comment : "-"}
      </TableCell>
      <TableCell size={size} align="center">
        {ai_comment ? ai_comment : "-"}
      </TableCell>
      {/* <TableCell size={size} align="center">
        <Tooltip title={"Подробнее"}>
          <Button variant="contained" size={size} onClick={onClick}>
            Детали
          </Button>
        </Tooltip>
      </TableCell> */}
      <TableCell size={size} align="center">
        {result?.price ? result.price : "-"}
      </TableCell>
    </TableRow>
  )
}

const Calls = ({ searchQuery, calls, setModalCall, size }) => {
  const callDetailsWrapper = (id, call) => () => setModalCall(call)

  return (
    <TableBody>
      {calls.results.map((c) => (
        <CallRow
          key={c.id}
          size={size}
          call={c}
          searchQuery={searchQuery}
          onClick={callDetailsWrapper(c.id, c)}
        />
      ))}
    </TableBody>
  )
}

const Footer = ({
  page,
  setCalls,
  setPage,
  rows,
  setRows,
  callsCount,
  scrollToTop,
}) => {
  const onRowsChange = (e) => {
    setRows(e.target.value)
    setPage(0)
    scrollToTop()
    setCalls(false)
  }
  const onChangePage = (e, p) => {
    setPage(p)
    scrollToTop()
    setCalls(false)
  }

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          page={page}
          onPageChange={onChangePage}
          count={callsCount}
          rowsPerPageOptions={[10, 25, 50]}
          rowsPerPage={rows}
          onRowsPerPageChange={onRowsChange}
          align="left"
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </TableRow>
    </TableFooter>
  )
}

const TableContent = ({
  calls,
  setCalls,
  setModalCall,
  size,
  fullTable,
  setFullTalbe,
  searchQuery,
  page,
  setPage,
  rows,
  setRows,
}) => {
  const tableRef = createRef()
  const scrollToTop = () =>
    tableRef.current && tableRef.current.scrollIntoView()

  if (calls.count < 1) return <NotCalls />
  return (
    <Box
      sx={{
        width: "100%",
        height: setFullTalbe ? "calc(100% - 40px)" : "100%",
        overflow: "auto",
        boxSizing: "border-box",
      }}
    >
      <Table stickyHeader ref={tableRef}>
        <Header size={size} calls={calls} />
        <Calls
          calls={calls}
          searchQuery={searchQuery}
          setModalCall={setModalCall}
          size={size}
        />
        <Footer
          size={size}
          page={page}
          setCalls={setCalls}
          callsCount={calls.count}
          setPage={setPage}
          rows={rows}
          setRows={setRows}
          scrollToTop={scrollToTop}
        />
      </Table>
    </Box>
  )
}

export default function CallsTable({
  filters,
  setFilters,
  calls,
  setCalls,
  setModalCall,
  page,
  setPage,
  fullTable,
  setFullTalbe,
  rows,
  setRows,
}) {
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"))
  // const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
  const size = isSmScreen ? "small" : "medium"

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "15px",
        // boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        {setFullTalbe && (
          <ExpandTable fullTable={fullTable} setFullTalbe={setFullTalbe} />
        )}
        <SearchByPhone filters={filters} setFilters={setFilters} />
        <SearchByWord filters={filters} setFilters={setFilters} />
      </Box>
      {calls ? (
        <TableContent
          calls={calls}
          setCalls={setCalls}
          setModalCall={setModalCall}
          searchQuery={filters?.phone || false}
          size={size}
          fullTable={fullTable}
          setFullTalbe={setFullTalbe}
          page={page}
          setPage={setPage}
          rows={rows}
          setRows={setRows}
        />
      ) : (
        <Loading />
      )}
    </Box>
  )
}
