import { useEffect, useState } from "react"
import { useSnackBarCustom } from "./useSnackbarCustom"
import api from "../shared/api/axios"

const getCustomersRequest = async (showSnackbar, startDate, endDate, page, LTV, rating, sort, phone, department) => {
  let params = new URLSearchParams({
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    lifetime_value_from: LTV[0],
    lifetime_value_to: LTV[1],
    rating_from: rating[0],
    rating_to: rating[1],
    page: page,
    page_size: 10,
    order_by_visits_frequency: ({1: undefined, 2: 1, 3: 0})[sort] ?? undefined
  })
  if (phone) {
    params.append("phone", phone)
  }
  if (department !== -1){
    params.append('department', department)
  }

  try {
    const res = await api.get(`customers/list?${params.toString()}`)
    return res.data
  } catch (error) {
    if (error.response && error.response.status === 403) {
      showSnackbar("Недостаточно прав доступа")
    } else {
      showSnackbar("Не удалось загрузить")
    }
    return false
  }
}

export default function useCustomers(startDate, endDate, page, LTV, rating, sort, phone, department) {
  const { showSnackbar } = useSnackBarCustom()
  const [customers, setCustomers] = useState(false)

  useEffect(() => {
    setCustomers(false)
    const load = async () => {
      const customers = await getCustomersRequest(showSnackbar, startDate, endDate, page, LTV, rating, sort, phone, department)
      setCustomers(customers)
    }
    load()
  }, [startDate, endDate, page, LTV, rating, sort, phone, department])

  return { customers, setCustomers }
}
